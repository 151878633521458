<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Puntos</h3>
                  <div class="nk-block-des text-soft">
                    <p>Se encontraron un total de {{total}} puntos.</p>
                  </div>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                        <li><router-link to="/sistema/puntos/nuevo" class="btn btn-white btn-outline-dark"><em class="icon ni ni-user-add"></em><span>Nuevo Punto</span></router-link></li>
                      </ul>
                    </div>
                  </div><!-- .toggle-wrap -->
                </div><!-- .nk-block-head-content -->
              </div><!-- .nk-block-between -->
            </div><!-- .nk-block-head -->
            <TableGrid :rows="rows" :filters="filters" :total="total" @update="update">
              <template #filters>
                <div class="card-tools between-start overflow-auto">
                  <div v-for="filter in filters" class="mr-3" :key="filter.key">
                    <input v-if="filter.type === 'text'" type="text" autocomplete="new" class="form-control w-min-150px" :placeholder="filter.label" v-model.lazy="filter.value">
                    <SelectRecorrido v-if="filter.label === 'Recorrido'" class="form-control w-min-150px"
                                    v-model:recorrido="filter.value" :all="true" placeholder="Recorrido">
                    </SelectRecorrido>
                  </div>
                </div>
              </template>
              <template #header>
                <div class="nk-tb-col"><span class="sub-text">ID</span></div>
                <div class="nk-tb-col"><span class="sub-text">Nombre</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Descripción</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Ubicación</span></div>
                <div class="nk-tb-col text-right"><span class="sub-text">Acciones</span></div>
              </template>
              <template v-slot:row="slot">
                <div class="nk-tb-col">
                  <span>{{slot.row.id_punto}}</span>
                </div>
                <div class="nk-tb-col">
                    <router-link :to="'/sistema/puntos/editar/'+slot.row.id_punto">
                      <span>{{slot.row.nombre}}</span>
                    </router-link>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{slot.row.descripcion}}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span><a href="#" @click.prevent="verUbicacion(slot.row)">Ver Ubicación</a></span>
                </div>
                <div class="nk-tb-col nk-tb-col-tools">
                  <ul class="nk-tb-actions gx-2">
                    <li>
                      <div class="drodown">
                        <a href="#" class="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li><router-link :to="'/sistema/puntos/editar/'+slot.row.id_punto"><em class="text-primary icon ni ni-user-list"></em><span>Editar Punto</span></router-link></li>
                            <li><a href="#" @click.prevent="remove(slot.row)"><em class="text-danger icon ni ni-user-remove"></em><span>Eliminar Punto</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TableGrid>
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import {reactive, ref, toRefs, defineComponent} from "vue";
import AppView from "@/components/sistema/AppView";
import TableGrid from "@/components/TableGrid";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import SelectRecorrido from "@/components/globales/SelectRecorrido.vue";
import {Dialog} from "quasar";
export default defineComponent({
  name: "Puntos",
  components: {SelectRecorrido, TableGrid, AppView},
  setup() {
    const router = useRouter();

    const state = reactive({
      rows: [],
      total: 1
    });
    const filters = ref([
      {
        key: 'nombre',
        label: 'Nombre',
        value: '',
        type: 'text'
      },
      {
        key: 'id_recorrido',
        label: 'Recorrido',
        value: '',
        type: 'select'
      }
    ]);

    const { loading, make } = useApi();

    async function update(config){
      loading.message = 'Obteniendo información';
      let {data} = await make('puntos/get-all', config);
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.total = data.total;
        }
      }
    }

    async function remove(punto){
      Swal.fire({
        title: 'Eliminar punto',
        text: '¿Estás seguro que deseas eliminar el punto ' + punto.nombre + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async res => {
        if(res.isConfirmed){
          loading.message = 'Eliminando punto';
          let {data} = await make('puntos/delete', {id_punto: punto.id_punto});
          if(data === null){
            await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if(data.error){
              await Swal.fire('Error', data.message, 'error');
            } else {
              router.go(0);
            }
          }
        }
      });
    }

    function verUbicacion(punto) {
      Dialog.create({
        title: punto.nombre,
        message: `<iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?hl=es&amp;q=${punto.lat},${punto.lng}+(${punto.nombre})&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />`,
        html: true
      })
    }

    return { ...toRefs(state), filters, remove, update, verUbicacion }
  }
});
</script>

<style scoped>
</style>
